import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  //   LinkedinShareButton,
  //   LinkedinIcon,
  //   TwitterShareButton,
  //   TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  //   RedditShareButton,
  //   RedditIcon
} from "react-share"

//export const ShareButtons = ({ twitterHandle, url, title, tags }) => (
export const ShareButtons = ({ url, title }) => (
  <div>
    <FacebookShareButton url={url}>
      <FacebookIcon size={48} />
    </FacebookShareButton>

    {/* <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
      <TwitterIcon />
    </TwitterShareButton>

    <LinkedinShareButton url={url}>
      <LinkedinIcon />
    </LinkedinShareButton>

    <RedditShareButton url={url} title={title}>
      <RedditIcon />
    </RedditShareButton> */}

    <WhatsappShareButton url={url} title={title}>
      <WhatsappIcon size={48} />
    </WhatsappShareButton>
  </div>
)

export default ShareButtons
