import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/shareButtons"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const pageContext = this.props.pageContext

    const siteUrl = this.props.data.site.siteMetadata.siteUrl

    let previousUrl, nextUrl, previousTitle, nextTitle, randomTitle, randomUrl

    if (pageContext.previous !== null) {
      previousUrl = pageContext.previous.fields.slug
      previousTitle = pageContext.previous.frontmatter.title
    }

    if (pageContext.next !== null) {
      nextUrl = pageContext.next.fields.slug
      nextTitle = pageContext.next.frontmatter.title
    }

    randomUrl = pageContext.random.fields.slug
    randomTitle = pageContext.random.frontmatter.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords={[
            `blog`,
            `samratchana`,
            `mahajyothi`,
            `baba`,
            `magazine`,
            `siva shankar baba`,
          ]}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <Img
                className="kg-image"
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                alt={post.frontmatter.title}
              />
            </div>
          )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <footer className="post-content-footer">
            {/* Switcharoo as new posts are at the top */}
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <span style={{ fontSize: 16 }}>Share this:</span>
                <ShareButtons
                  url={`${siteUrl}${pageContext.slug}`}
                  title={`${siteTitle} | ${post.frontmatter.title}`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col link-block">
                <Link
                  disabled={!nextUrl}
                  className={nextUrl ? "" : "disabled-link"}
                  to={nextUrl}
                >
                  {" "}
                  &lsaquo; Previous
                </Link>
                <h6>{nextTitle}</h6>
              </div>
              <div className="col link-block">
                <Link
                  disabled={!previousUrl}
                  className={previousUrl ? "" : "disabled-link"}
                  to={previousUrl}
                >
                  Next &rsaquo;
                </Link>
                <h6>{previousTitle}</h6>
              </div>
            </div>
            <div className="row">
              <div className="col link-block">
                <Link
                  disabled={!randomUrl}
                  className={randomUrl ? "" : "disabled-link"}
                  to={randomUrl}
                >
                  Random
                </Link>
                <h6>{randomTitle}</h6>
              </div>
            </div>
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
